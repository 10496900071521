<template>
    <div class="bg-white block w-full md:flex h-full relative mx-auto">
        <div class="relative h-full w-full">
            <div class="w-full h-full overflow-x-hidden">
                <div class="relative h-full w-full">
                    <div class="py-4 px-4 flex flex-wrap md:px-4 text-gray-700 pb-[50px] grid md:grid-cols-2 gap-x-2 sm:block border-r-2 border-gray-200">
                        <div class="md:w-full">
                            <div class="text-md font-bold pb-2 border-b border-[#DEE8F9]">
                                <i class="w-4 h-4 bg-contain bg-center bg-[url(/images/icons/essay-writer.webp)] inline-flex"></i>
                                <span class="text-base ml-1">Sentence Counter</span>
                            </div>
                            <label for="sentenceCounter" class="flex text-sm font-semibold mt-3">Write your Text</label>
                        </div>
                        <div class="md:w-full">
                            <textarea id="sentenceCounter" rows="6" v-model="rawtext" class="w-full border-gray-200 rounded-md mt-1"></textarea>
                            <div class="flex flex-wrap w-full items-center justify-between">
                                <p class="text-lg text-right text-gray-700"><b>Word Count:</b> {{ wordCount }}</p>
                                <p class="text-lg text-right text-gray-700"><b>Character Count:</b> {{ charCount }}</p>
                                <p class="text-lg text-right text-gray-700"><b>Sentence Count:</b> {{ sentenceCount }}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ref, computed } from "vue";
import axios from "axios";
export default {
    components: {
        QuillEditor
    },
    props: {
        translations: Object,
    },
    data() {
        return {
            rawtext: '',
            editortext: null,
            token: null,
            loading: false
        };
    },
    mounted(){
        this.renderCf();
    },
    beforeMount() {
        this.cfInit();
    },
    methods:{
        cfInit(){
            const e = document.createElement("script"), t = "https://challenges.cloudflare.com/turnstile/v0/api.js";
            e.src = `${t}?onload=onloadTurnstileCallback&render=explicit`, e.async = !0, e.defer = !0, document.head.appendChild(e);
        },
        renderCf(){
            let _this = this;
            window.onloadTurnstileCallback = function () {
                turnstile.render('#cf-container', {
                    sitekey: _this.sitekey,
                    callback: function(token) {
                        _this.verify(token);
                    },
                });
            };
        },
        verify(token){
            this.token = token;
        },
        doParaphrase() {
            this.loading = true;
            axios
                .post("/tool/sentenceCounter", {
                    capcha_token: this.token,
                    rawtext: this.rawtext,
                    lang: 'english',
                    mode: 'creative',
                })
                .then(response=>{
                    this.loading = false;
                    if (response.data.content.length){
                        this.editortext = response.data.content;
                    }
                    turnstile.reset('#cf-container')
                })
                .catch(e => {
                    let message = "something went wrong please try again.";
                    if(e.response.status == 422) {
                        message = e.response.data.message;
                    }
                    alert(message);
                    this.loading =false;
                    turnstile.reset('#cf-container')
                })
        }
    },
    computed: {
        wordCount() {
            return this.rawtext.trim().split(/\s+/).length;
        },
        charCount() {
            return this.rawtext.length;
        },
        sentenceCount() {
            const sentenceRegex = /[^\.!\?]+[\.!\?]+/g;
            const sentences = this.rawtext.match(sentenceRegex);
            return sentences ? sentences.length : 0;
        }
    }
};
</script>

<style>
.ql-editor {
    height: 100% !important;
}
.ql-container {
    height: auto !important;
}
.ql-container.ql-snow {
    border: none !important;
}
.ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: 1px solid rgb(229 231 235);
}
</style>
